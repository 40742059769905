import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import { MdAdd, MdRemove } from 'react-icons/md';
import { useSelector } from "react-redux";
import bn from '../utils/bemnames';
const bem = bn.create('footer');

const Footer = () => {
  const [footerList] = useState([]);
  const [selectedRoot, setSelectedRoot] = useState('');

  const footerContentCS = useSelector(state => state?.layoutState?.layout?.menus?.footerContent[0]);

  // let countryCode = '';

  // switch (window.location.hostname.split('.')[window.location.hostname.split('.').length - 1]) {
  //   case "my":
  //     countryCode = 'my';
  //     break;
  //   case "sg":
  //     countryCode = 'sg';
  //     break;
  //   case "bn":
  //     countryCode = 'bn';
  //     break;
  //   default:
  //     countryCode = 'my';
  // }

  // useEffect(() => {
  //   if (footerList.length === 0) {
  //     getFooterMenus('en-my').then(res => {
  //       // TODO: language
  //       const footerRawList = res.data;
  //       let finalList = [];

  //       // get level 1
  //       footerRawList.map(raw => {
  //         if (raw.reference.length === 0) {
  //           // find sub list
  //           let children = footerRawList.filter(
  //             f => f.reference.length > 0 && f.reference[0].uid === raw.uid,
  //           );

  //           // children - sorting
  //           children.map(c => (c.sequence = c.sequence ?? 0));
  //           children = children.sort((a, b) => a.sequence - b.sequence);

  //           raw.children = children;
  //           raw.sequence = raw.sequence ?? 0;
  //           finalList.push(raw);
  //         }
  //       });

  //       finalList = finalList.sort((a, b) => a.sequence - b.sequence);
  //       setFooterList(finalList);
  //     });
  //   }
  // }, [footerList]);

  const onFooterMenuClick = e => {
    e.stopPropagation();
    let uid = e.currentTarget.getAttribute('uid');
    if (uid !== selectedRoot) setSelectedRoot(uid);
    else setSelectedRoot('');
  };

  const handleLinkClick = url => {
    if (top.location != self.location)
      top.location = url;
    else
      window.location.href = url;
  };

  // const listLinkByCountry = (country) => {
    // switch (country) {
    //   case "my":
    //   case "bn":
    //     return {
    //       facebook: 'https://www.facebook.com/amwaymalaysia',
    //       instagram: 'https://www.instagram.com/amwaymy',
    //       youtube: 'http://www.youtube.com/amwaymalaysia',
    //       telegram: 'https://t.me/AmwayMalaysiaOfficialChannel',
    //     }
    //   case "sg":
    //     return {
    //       facebook: 'https://www.facebook.com/amwaysingapore',
    //       instagram: 'https://www.instagram.com/amway.sg/',
    //       youtube: 'https://www.youtube.com/amwaysingapore',
    //       telegram: 'https://t.me/amwaycommunity',
    //     }
    //   default:
    // }
  // }

  // const handleSocialMediaLinkClick = type => {
  //   if (type === 'facebook') {
  //     if (top.location != self.location)
  //       top.location = listLinkByCountry(countryCode).facebook;
  //     else
  //       window.location.href = listLinkByCountry(countryCode).facebook;
  //   } else if (type === 'instagram') {
  //     if (top.location != self.location)
  //       top.location = listLinkByCountry(countryCode).instagram;
  //     else
  //       window.location.href = listLinkByCountry(countryCode).instagram;
  //   } else if (type === 'youtube') {
  //     if (top.location != self.location)
  //       top.location = listLinkByCountry(countryCode).youtube;
  //     else
  //       window.location.href = listLinkByCountry(countryCode).youtube;
  //   }
  // };

  const handleClickLink = link => {
    const urlExtension = link.split(/[#?]/)[0].split('.').pop().trim();
    if (urlExtension === 'pdf') {
      window.open(link);
    } else {
      if (top.location != self.location)
        top.location = link;
      else
        window.location.href = link;
    }
  };

  //console.log(footerList);
  return (
    <footer className={bem.b()}>
      <Navbar>
        <Nav navbar>
          {footerList.map(footer => {
            return (
              <div key={footer.uid}>
                <NavItem
                  onClick={onFooterMenuClick}
                  uid={footer.uid}
                >
                  {footer.title}
                  {selectedRoot === footer.uid && <MdRemove size={20} />}
                  {selectedRoot !== footer.uid && <MdAdd size={20} />}
                </NavItem>
                {selectedRoot === footer.uid && footer.children.length > 0 && (
                  <>
                    {footer.children.map(sub => {
                      return (
                        <NavItem key={sub.uid} className="sub-footer">
                          {sub.url.toString().toLowerCase().includes("https://") ?
                            <div
                              id={`sub-footer-${sub.uid}`}
                              onClick={e => {
                                e.stopPropagation();
                                handleLinkClick(sub.url);
                              }}
                            >
                              <span className="">{sub.title}</span>
                            </div>
                            :
                            <BSNavLink
                              id={`sub-footer-${sub.uid}`}
                              tag={NavLink}
                              to={sub.url}
                              activeClassName="active"
                              exact={false}
                            >
                              <span className="">{sub.title}</span>
                            </BSNavLink>}
                        </NavItem>
                      );
                    })}
                  </>
                )}
              </div>
            );
          })}
        </Nav>
      </Navbar>
      <div className='media'>
        <span>{footerContentCS?.connect_with_amway}</span>
        <div className='logo'>
          {/* <a href='https://www.facebook.com/amwaymalaysia' ><img src={facebookIcon} title='facebook.com' alt='facebook' /></a>
          <a href='https://www.instagram.com/amwaymy' ><img src={instagramIcon} title='instagram.com' alt='instagram' /></a>
          <a href='http://www.youtube.com/amwaymalaysia'><img src={youtubeIcon} title='youtube.com' alt='youtube' /></a> */}
          {footerContentCS?.connect_media?.map(m => (
            <div
              key={m._metadata?.uid}
              onClick={e => {
                e.stopPropagation();
                handleClickLink(m.link?.href);
              }}
            >
              <img src={m.icon?.url} title={m.link?.title} alt={m.alt} />
            </div>
          ))}
        </div>
      </div>
      <div className='copyright'>

        <p style={{margin: 'auto', width: '290px'}}>
          {footerContentCS?.details?.terms?.map((t, idx) => (
            <span key={t._metadata?.uid} 
              onClick={e => {
                e.stopPropagation();
                handleClickLink(t.link?.href);
              }}
              style={{cursor:'pointer'}}
            >
              {idx > 0 && ' | '}
              {t.link?.title}
            </span>
          ))}
        </p>
        <p>
          {footerContentCS?.details?.contact
            ?.split("<br/>")?.map((str, idx) => (
              <span key={idx}>
                {idx > 0 && <br/>}
                {str}
              </span>
            ))
          }
        </p>
        <p>
          {footerContentCS?.details?.copyright
            ?.replace('{copyright-year}', new Date().getFullYear())
            ?.split("<br/>")?.map((str, idx) => (
              <span key={idx}>
                {idx > 0 && <br/>}
                {str}
              </span>
            ))
          }
        </p>
        {footerContentCS?.details?.credit?.map(c => (
          <img key={c._metadata?.uid} src={c.img?.url} title={c.link?.title} alt={c.alt} 
            onClick={e => {
              e.stopPropagation();
              handleClickLink(c.link?.href);
            }}
            style={{height: '40px'}}
          />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
