import React, { useState, useEffect } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Nav, NavItem, NavLink as BSNavLink } from 'reactstrap';
import bn from '../utils/bemnames';

import logOutIcon from '../assets/icon/LogOut.png';
import Strings from "../utils/locale";
import PopupConfirm from '../components/popupConfirm/popupConfirm';
import { logoutSession } from '../utils/login';

import { useSelector } from "react-redux";

const bem = bn.create('pop-menu');
const helper = require('../utils/helper');

const Menu = () => {

  const contentStackData = useSelector(state => state?.layoutState?.layout);

  const [menus] = useState([]);
  const [isAuthenticated] = useState(true);
  const [multilingualMenus, setMultilingualMenus] = useState([]);
  const [curCountry, setCurCountry] = useState({ countryCode: "", countryName: "",});
  const [countries] = useState([
    { countryCode: "ph", countryName: Strings.COUNTRY_PHILIPPINES}
  ]);
  const [showLogout, setShowLogout] = useState(false);

  // from contentstack
  // const [dataCS, setDataCS] = useState({});
  const [layoutCS, setLayoutCS] = useState({});

  useEffect(() => {
    // setDataCS(contentStackData?.data);
    setLayoutCS(contentStackData?.layout?.mp);

    //TODO: language option
    const data = contentStackData?.menus?.multiLang;
    let sorted = data.sort(
      (a, b) => parseInt(a.sequence) - parseInt(b.sequence),
    ); // sort ascending


    setMultilingualMenus(sorted);
    // setSupportedCountry(supportedCountry);
    // setSupportedLanguage(supportedLanguage);

    getCurCountry();
  }, [contentStackData])

  const getCurCountry = () => {
    const country = localStorage.getItem('country') || 'ph';
    let countryItem = "";

    switch (country) {
      case "ph":
        countryItem = countries.filter(i => i.countryCode === "ph");
        break;
      default:
        countryItem = countries.filter(i => i.countryCode === "ph");
        break;
    }
    setCurCountry(countryItem[0])
  };

  const handleLaunchTutorial = () => {
    localStorage.setItem('onTour', true);
    window.location.reload(1);
  }

  const handleLogoutPopup = () => {
    setShowLogout(true);
  }

  const handleActionLogout = () => {
    logoutSession();
    setShowLogout(false);

    let currentPath = window.location.hostname;
    if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
      window.location.href = "/";
    } else if (currentPath === 'uat.biz.amway.com.ph' || currentPath === 'biz.amway.com.ph' || currentPath === 'psup.biz.amway.com.ph') {
      if (top.location != self.location)
        top.location = process.env.REACT_APP_LOGOUT_URL;
      else
        window.location.href = process.env.REACT_APP_LOGOUT_URL;

    }
  };

  const handleActionCancel = () => {
    setShowLogout(false);
  };

  console.log('curCountry', curCountry);
  console.log('layoutCS', layoutCS);

  return (
    <>
      {!isAuthenticated ? (
        <Redirect to={helper.getLandingPage()} />
      ) : (
        <aside className={bem.b()}>
          <div className={bem.e('background')} />
          <div className={bem.e('content')}>
            <Nav vertical>
              {menus.length > 0 &&
                menus?.map((m, i) => {
                  return (
                    <NavItem
                      key={i}
                      className={`${bem.e('nav-item')}`}
                      title={m.title}
                    >
                      <BSNavLink
                        id={`navItem-${m.uid}`}
                        tag={NavLink}
                        to={m.url}
                        activeClassName="active"
                        exact={false}
                      >
                        <span className="">{m.title}</span>
                      </BSNavLink>
                    </NavItem>
                  );
                })
              }

              {multilingualMenus.length > 0 &&
                multilingualMenus.map((m, i) => {
                  return (
                    <div key={i}>
                      {m.title !== "Country" && m.title !== "Language" && m.title !== "Sign Out" && m.title !== "Tutorial" &&
                        <NavItem
                          className={`${bem.e('nav-item')}`}
                          title={m.title}
                        >
                          <BSNavLink
                            id={`navItem-${m.uid}`}
                            tag={NavLink}
                            to={m.url}
                            activeClassName="active"
                            exact={false}
                            style={{ fontWeight: "600" }}
                          >
                            <span className="">{m.title}</span>
                          </BSNavLink>
                        </NavItem>
                      }
                      {m.title === "Tutorial" && (
                        <div key={(i + 1) * 100} style={{ padding: "0px 16px" }}>
                          <div className={`${"navItem"}`} key={i}>
                            <a onClick={handleLaunchTutorial}>
                              <div className={"text"} style={{ fontWeight: "700", paddingLeft: "0" }}>{m.value}</div>
                            </a>
                          </div>
                        </div>
                      )}
                      {m.title === "Sign Out" ?
                        <>
                          <div className='dividing-line' />
                          <div key={(i + 1) * 100} style={{ padding: "0px 16px" }}>
                            <div className={`${"navItem"}`} key={i}>
                              <a onClick={handleLogoutPopup}>
                                <div className={"logout_icon"}>
                                  <img src={logOutIcon} />
                                </div>
                                <div className={"text"} style={{ fontWeight: "700" }}>{m.value}</div>
                              </a>
                            </div>
                          </div>
                        </>
                        : null
                      }


                    </div>
                  );
                })}

              <PopupConfirm
                title={multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.value || 'Sign Out'}
                showPopup={showLogout}
                okBtn={{
                  text: `${multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.confirm_button || 'Confirm'}`,
                  action: handleActionLogout,
                }}
                cancelBtn={{
                  text: `${multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.cancel_button || 'Cancel'}`,
                  action: handleActionCancel,
                }}
              >
                <span>{multilingualMenus?.filter(i => i.title === 'Sign Out')[0]?.sign_out?.message || 'Sign out your current account'}</span>
              </PopupConfirm>
            </Nav>
          </div>
        </aside>
      )}
    </>
  )
}

export default Menu;
