import Cookies from 'js-cookie';
//import $ from 'jquery';
import axios from 'axios';
import { getTokens } from '../../../utils/loginSession';
import { isTokenValid, isABOToken } from '../../../utils/helper';

const config = require('../../../config');
const helper = require('../../../utils/helper');
const ux = require('../../ux-tracking-center');
const enums = require('../../../enums');

export const asLocalAdmin = () => {
  return (
    helper.getEnvironment() === 'LOCAL' || helper.getEnvironment() === 'ISS'
  ); // this is for local server use only
};

export const getAccessToken = () => {
  const atoken = getTokens().accessToken;

  return atoken;
}

export const isAuthenticated = () => {
  const atoken = getAccessToken();
  const isExpired = !isTokenValid(atoken);

  if (atoken && typeof atoken !== 'undefined' && atoken !== 'undefined' && atoken !== '' && !isExpired) {
    return true;
  }
  return false;
};

export const checkCountry = () => {

  if (window.location.hostname === 'localhost' || window.location.hostname === '172.0.0.1')
    return true;

  let currentPath = window.location.origin;
  switch (localStorage.getItem('country')) {
    case "ph":
      if (currentPath !== process.env.REACT_APP_BUSINESSDASHBOARD_URL)
        return false;
      return true;
    default:
      return false;
  }
};

export const isABO = () => {
  const atoken = getAccessToken();
  const isABO = isABOToken(atoken);

  if (atoken && typeof atoken !== 'undefined' && atoken !== 'undefined' && atoken !== '' && isABO) {
    return true;
  }
  return false;
};

export const doLogin = async (username, password, isSSO, empId, storeId) => {
  resetUserData();

  if (username === 'local') {
    // for local server use
    localStorage.setItem('isAdmin', true);
    return {
      status: true,
      message: '',
      isAdmin: true,
      isFloater: false,
    };
  }

  if (username === 'store') {
    // for local server use
    return {
      status: true,
      message: '',
      isAdmin: false,
      isFloater: true,
    };
  }

  try {
    let logindata = { username: username, password: password };
    let url = '/Login/';

    if (isSSO) {
      url = '/getADuser/';

      if (
        storeId !== '' &&
        storeId !== 'null' &&
        storeId !== 'undefined' &&
        storeId !== undefined
      ) {
        if (empId.trim() !== '')
          logindata = {
            impEmp: empId,
            floaterStoreId: storeId,
            external: false,
          };
        else
          logindata = {
            empid: username,
            floaterStoreId: storeId,
            external: false,
          };
      } else {
        storeId = '';
        if (username.trim() !== '')
          logindata = {
            empid: username,
            floaterStoreId: storeId,
            external: false,
          };
        else
          logindata = {
            impEmp: empId,
            floaterStoreId: storeId,
            external: false,
          };
      }
    }

    const requestOptions = {
      crossDomain: true,
      method: 'POST',
      headers: { ...config.api.headers },
      body: JSON.stringify(logindata),
    };

    console.log(helper.getAPIHost() + url, requestOptions);
    return await axios({
      url: helper.getAPIHost() + url,
      method: 'POST',
      data: logindata,
    })
      .then(async response => {
        console.log(response);
        let data = response.data;

        const result = data.result;
        // let isAdminLoginSSo = localStorage.getItem("isAdminLoginSSo") || false; // for admin login again using the same id after SSo
        if (data.status === 'OK') {
          const user = result.user;
          if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            parseInt(user.userGroupId) === enums.userGroups.SystemAdmin &&
            !user.isFloating /*&& !isAdminLoginSSo*/
          ) {
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isAdmin', true);
            // localStorage.setItem("isAdminLoginSSo", true);
            return {
              status: true,
              message: '',
              isAdmin: true,
              isFloater: false,
            };
          } else if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            user.isFloating &&
            (user.storeId === '' || user.storeId === null)
          ) {
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isFloater', true);
            localStorage.setItem('FloaterID', username);
            return {
              status: true,
              message: '',
              isFloater: true,
              empID: user.emplId,
            };
          } else if (
            typeof user.userGroupId !== 'undefined' &&
            user.userGroupId !== null &&
            user.isFloating &&
            user.storeId === '1' &&
            storeId === ''
          ) {
            // special for store 1
            ux.logAuthenticated(isSSO);
            localStorage.setItem('isFloater', true);
            localStorage.setItem('FloaterID', username);
            return {
              status: true,
              message: '',
              isFloater: true,
              floaterStore: user.storeId,
              empID: user.emplId,
            };
          } else {
            if (user.userGroupId !== 0) {
              //const user = result.user;
              const expDate = new Date(result.tokenExpiration);
              Cookies.set('appToken', result.appToken, { expires: expDate });
              localStorage.setItem('appToken', result.appToken);
              localStorage.setItem(
                'authentication',
                'Bearer ' + result.appToken,
              );
              localStorage.setItem('TokenExpiration', expDate);

              let params = [];
              if (result.parameterSetup) {
                params = result.parameterSetup;
              }

              let paramKeys = {};
              params.map(p => {
                paramKeys[p.paramName] = p.paramValue;
                return 0;
              });
              localStorage.setItem('Parameters', JSON.stringify(paramKeys));

              ux.logAuthenticated(isSSO);

              return {
                status: true,
                message: '',
                isAdmin: false,
                isFloater: false,
              };
            } else {
              ux.logError(
                'Framework',
                'Login',
                'Invalid usergroup from Login',
                JSON.stringify(result),
              );
              return {
                status: false,
                message: '',
                isAdmin: false,
                isFloater: false,
              };
            }
          }
        } else {
          ux.logError(
            'Framework',
            'Login',
            'Invalid username or password',
            JSON.stringify(logindata),
          );
          return {
            status: false,
            message: isSSO ? '' : 'Invalid Username or Password!',
          };
        }
      })
      .catch(error => {
        ux.logError(
          'Framework',
          'Login',
          'Unable to connect to server!',
          JSON.stringify(logindata),
        );
        console.log(error);
        return {
          status: false,
          message: 'Unable to connect to server!',
        };
      });
  } catch (error) {
    ux.logError('Framework', 'Login', 'Error on login process!', error);
    Promise.resolve({
      status: false,
      message: 'Error on login process!',
    });
  }
};

export const doLogout = async () => {
  console.log('In Logout');
  if (localStorage.getItem('appToken')) {
    const requestOptions = {
      method: 'POST',
      body: {},
      ...helper.apiHeaders(),
    };
    await fetch(helper.getAPIHost() + '/api/Logout/', requestOptions).then(
      async () => {
        try {
          console.log('Logout from server');
        } catch (e) {
          void e;
        }
      },
    );
  }
  ux.logExit();
  resetUserData();

  setTimeout(function () {
    window.location.href = '/login?fromLogout=logout';
  }, 1000);

  return true;
};

export const resetUserData = () => {
  Cookies.set('appToken', '', { expires: -1 });

  // Token information
  localStorage.removeItem('appToken');
  localStorage.removeItem('TokenExpiration');
  localStorage.removeItem('authentication');
  //localStorage.removeItem("SSOUser");// cannot remove

  // Employee data

  // Setup information

  console.log('Data cleared');

  // KPIs
};
