import jwt from "jsonwebtoken";
import moment from "moment";
const config = require('../config');

export const getAPIVersion = () => {
  return localStorage.getItem('appVersion') || '';
};
export const getEmplId = () => localStorage.getItem('EmplId') || '';

export const getAPIHost = () => {
  if (window.location.hostname !== 'localhost' && window.location.hostname !== '172.0.0.1') {
    return process.env.REACT_APP_BFF_API || `${window.location.origin}/api`;
  }
  else {
    return process.env.REACT_APP_BFF_API || "//localhost:3010/api";
  }
}

export const getLandingPage = () => process.env.REACT_APP_LANDING_PAGE;

export const apiHeaders = () => {
  return {
    headers: {
      Authorization: localStorage.getItem('authentication'),
      appToken: localStorage.getItem('appToken'),
      ...config.api.headers,
    },
  };
};

export const getEnvironment = type => {
  if (type === '' || !type) {
    return process.env.REACT_APP_ENV === 'PROD'
      ? ''
      : process.env.REACT_APP_ENV;
  } else {
    let name =
      process.env.REACT_APP_ENV === 'PROD' ? '' : process.env.REACT_APP_ENV;
    if (name === 'ISS') name = 'issdev';
    return name.toLowerCase();
  }
};

export const isLocal = () =>
  process.env.REACT_APP_ENV === 'LOCAL' || process.env.REACT_APP_ENV === 'ISS';
export const isDEV = () => process.env.REACT_APP_ENV === 'DEV';
export const isQA = () => process.env.REACT_APP_ENV === 'QA';
export const isProd = () => process.env.REACT_APP_ENV === 'PROD';

export const checkAPIVersion = async () => {
  let check = false;
  let currentTime = parseInt(new Date().getTime() / 1000);
  let lastCheck = parseInt(localStorage.getItem('versionLastCheck') || 0);

  if (lastCheck === 0) {
    localStorage.setItem('versionLastCheck', currentTime);
  } else {
    if (currentTime - 3600 > lastCheck) {
      check = true;
    }
  }

  if (check) {
    try {
      console.log('check version');
      await fetch(getAPIHost() + '/getVersion', {
        method: 'GET',
        ...apiHeaders(),
      }).then(async data => {
        const json = await data.json();
        console.log(data);
        console.log(json);

        localStorage.setItem('versionLastCheck', currentTime);
      });
    } catch (r) {
      console.log('Check version ');
      console.log(r);
    }
  }
};

export const formatNumber = (number, decimal, prefix, postfix) => {
  if (isNaN(number)) number = 0;
  number = parseFloat(number);
  if (!prefix) prefix = '';
  if (!postfix) postfix = '';
  return (
    `${prefix ? prefix + ' ' : ''}` +
    number.toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    }) +
    `${postfix ? ' ' + postfix : ''}`
  );
};

export const getMonthName = (number, string, abbreviate = false) => {
  let months = [
    { name: string?.jan?.full_name || 'January', sf: string?.jan?.short_name || 'Jan' },
    { name: string?.feb?.full_name || 'February', sf: string?.feb?.short_name || 'Feb' },
    { name: string?.mar?.full_name || 'March', sf: string?.mar?.short_name || 'Mar' },
    { name: string?.apr?.full_name || 'April', sf: string?.apr?.short_name || 'Apr' },
    { name: string?.may?.full_name || 'May', sf: string?.may?.short_name || 'May' },
    { name: string?.jun?.full_name || 'June', sf: string?.jun?.short_name || 'June' },
    { name: string?.jul?.full_name || 'July', sf: string?.jul?.short_name || 'July' },
    { name: string?.aug?.full_name || 'August', sf: string?.aug?.short_name || 'Aug' },
    { name: string?.sep?.full_name || 'September', sf: string?.sep?.short_name || 'Sept' },
    { name: string?.oct?.full_name || 'October', sf: string?.oct?.short_name || 'Oct' },
    { name: string?.nov?.full_name || 'November', sf: string?.nov?.short_name || 'Nov' },
    { name: string?.dec?.full_name || 'December', sf: string?.dec?.short_name || 'Dec' },
  ];
  return abbreviate ? months[number - 1].sf : months[number - 1].name;
};

export const getDayName = number => {
  let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[number];
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

export const getQSParam = key => {
  let qs = new URLSearchParams(window.location.search);
  return qs.get(key);
};

export const shortNumber = (n, d) => {
  if (n < 1000) return parseFloat(n).toFixed(d);
  else if (n < 1000000) return parseFloat(n / 1000).toFixed(d) + 'K';
  else return parseFloat(n / 1000000).toFixed(d) + 'M';
};

export const sort = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!a.key || !b.key) {
      // property doesn't exist on either object
      return 0;
    }

    const varA =
      typeof a[key] === 'string' ? a[key].toUpperCase() : a[key] || '';
    const varB =
      typeof b[key] === 'string' ? b[key].toUpperCase() : b[key] || '';

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const dateYYYYMMDD = str => {
  let s = str.substr(0, 10).split('-');
  return new Date(s[0], parseInt(s[1]) - 1, s[2], 0, 0, 0, 0);
};

export const formatDate = (str, format) => {
  // from YYYY-MM-DD
  if (str === '' || str === null || str === undefined) return '';
  else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    // for date with timezone
    let d = new Date(str);
    if (format === 'dd/mm/yyyy')
      return (
        zeroPad(d.getDate(), 2) +
        '/' +
        zeroPad(d.getMonth() + 1, 2) +
        '/' +
        d.getFullYear()
      ); // return dd/mm/yyyy
    else
      return (
        zeroPad(d.getMonth() + 1, 2) +
        '/' +
        zeroPad(d.getDate(), 2) +
        '/' +
        d.getFullYear()
      ); // return mm/dd/yyyy
  } else {
    // for date only
    let s = str.split('-');
    return s[1] + '/' + s[2] + '/' + s[0]; // return mm/dd/yyyy
  }
};

export const formatNumber2 = (number, digit) => {
  if (isNaN(number)) number = 0;
  let digitStr = '';
  for (let i = 1; i < digit; i++) {
    digitStr = digitStr + '0';
  }
  var formattedNumber = (digitStr + number).slice(-digit);
  return formattedNumber;
};

export const formatFullDate = dateString => {
  let date = new Date(dateString);
  let year = formatNumber2(date.getFullYear(), 2);
  let month = formatNumber2(parseInt(date.getMonth()) + 1, 2);
  let day = formatNumber2(date.getDate(), 2);
  return `${day}/${month}/${year}`;
};

export const formatDateYYYYMM = dateString => {
  let date = new Date(dateString);
  let year = formatNumber2(date.getFullYear(), 4);
  let month = formatNumber2(parseInt(date.getMonth()) + 1, 2);
  return `${year}${month}`;
};

export const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

export const getTimeforDate = str => {
  if (str === '' || str === null || str === undefined) return '';
  else if (str.indexOf('T') > 0 || str.indexOf(' ') > 0) {
    var today = new Date(str),
      // time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      time = today.toLocaleString('en-US', { hour: 'numeric', hour12: true });
    return time;
  }
};

export const arrayRemoveNull = array => {
  let newArray = array.filter(item => {
    return item;
  });
  return newArray;
};

export const inputFocus = () => {
  //document.querySelector(".cr-page").style.marginBottom = "30vh"
};

export const isEmpty = obj => {
  for (var prop in obj) {
    if (obj[prop]) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const phoneNumberFormat = str => {
  if (str.indexOf('-') === 3 && str.indexOf('-', 4) === 7) return str;
  else {
    var cleaned = ('' + str).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return match[1] + '-' + match[2] + '-' + match[3];
    }
    return null;
  }
};

export const removeSpecialChar = text => {
  return text.replace(/[^a-zA-Z0-9]/g, '');
};

export const catchServer = res => {
  if (res?.server) {
    console.log('APP Server', res.server);
    localStorage.setItem('APP_SERVER', res.server);
  }
  if (
    !localStorage.getItem('WEB_SERVER') ||
    localStorage.getItem('WEB_SERVER') !== process.env.REACT_APP_SERVER
  ) {
    localStorage.setItem('WEB_SERVER', process.env.REACT_APP_SERVER);
  }
};

export const getRouter = () => {
  if (typeof window === "undefined") return {};
  return window.useRouter && window.useRouter();
};

export const getCurrentPath = () => {
  const router = getRouter();
  let path = "";
  if (router && router.asPath) path = router.asPath;
  else if (router && router.location && router.location.pathname)
    path = router.location.pathname;
  return path;
};

export const isTokenValid = (t) => {
  if (t) {
    const tk = jwt.decode(t);
    if (tk?.exp && tk?.aud && tk?.iat) {
      return new Date().getTime() < tk.exp * 1000;
    }
    return false;
  }
  return "";
};

export const isABOToken = (t) => {
  if (t) {
    const tk = jwt.decode(t);
    if (tk?.account?.acct_type && tk?.account?.acct_subtype) {
      return tk?.account?.acct_type.toLowerCase() === 'amwaybusiness' && tk?.account?.acct_subtype.toLowerCase() === 'businessowner';
    }
    return false;
  }
  return "";
};

export const getCurrencySymbol = (currencyCode) => {

  if (currencyCode === "PHP") return "₱";

  return;
}

export const isIOS = () => {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const isMobile = () => {
  if (typeof window !== "undefined") {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  } else return false;
};

const isGarQualPeriodValid = (period) => {
  if (period && typeof period !== 'undefined' && period !== 'undefined') {
    let year = parseInt(period.substring(0, 4)) + 1;
    let month = parseInt(period.substring(4, 6)) - 1;
    if (new Date(year, month) > new Date()) {
      return true;
    }
    return false;
  }
  return "";
};

export const getCurrentPinValue = (currentHighestGarAwards, currentAwardRank) => {
  let currentPin = '';
  if (isGarQualPeriodValid(currentHighestGarAwards?.currentGarAwardQualPeriod)) {
    currentPin = currentHighestGarAwards?.currentGarAwardRank;
  } else {
    if (parseInt(currentAwardRank) >= 390)
      currentPin = '385';
    else
      currentPin = currentAwardRank?.toString();
  }

  return currentPin;
};

// Format Date mm/yyyy
export const format = inputDate => {
  let month, year;
  month = inputDate.getMonth() + 1;
  year = inputDate.getFullYear();

  month = month
    .toString()
    .padStart(2, '0');

  return `${month}/${year}`;
}

export const validPeriod = (expireDate, months) => {
  const curDate = new Date();
  const addedDate = moment(curDate).add(months, 'months').format();

  if (new Date(expireDate) <= new Date(addedDate)) {
    return true;
  }

  return false;
};
