import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './utils/axios-interceptor';

import { EmptyLayout, LayoutRoute, MainLayout } from './framework-layout';
import PageSpinner from './components/PageSpinner';
import Health from './modules/index';

import './styles/reduction.scss';

import TestPost from './modules/testPost';
import Mobile from './modules/mobile';

// Import routes
const Routes_shared = require('./routes');

// Combine all routes
const routes = [...Routes_shared.routes];

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

// module, to hold the page object
window.message = {};
window.redirect = {};
window.enums = {};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <Switch>
          <LayoutRoute
            exact
            path="/testPost"
            layout={EmptyLayout}
            component={() => <TestPost />}
          />

          <LayoutRoute
            exact
            path="/health"
            layout={EmptyLayout}
            component={() => <Health />}
          />

          <LayoutRoute
            exact
            path="/mobile"
            layout={EmptyLayout}
            component={() => <Mobile />}
          />

          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={Routes_shared.LandingPage} />
              <Route
                exact
                path="/home"
                component={Routes_shared.DashboardPage}
              />

              {routes.map((c, i) => (
                <Route exact key={i} path={c.path} component={c.component} />
              ))}
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width <= 575) {
    return { breakpoint: 'xxs' };
  }

  if (576 <= width && width < 767) {
    return { breakpoint: 'xs' };
  }

  if (768 <= width && width < 991) {
    return { breakpoint: 'sm' };
  }

  if (992 <= width && width < 1199) {
    return { breakpoint: 'md' };
  }

  if (1200 <= width && width < 1920) {
    return { breakpoint: 'lg' };
  }

  return { breakpoint: 'xl' };
};

export default componentQueries(query)(App);
