/**
 *
 * en-ph
 *
 * Philippines English locale labels
 */

export const Strings = {
  COUNTRY_PHILIPPINES: "Philippines",


  DASHBOARD_MY_PERFORMANCE: "My Performance",
};
