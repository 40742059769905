/**
 *
 * Main locale file for this MSB Business Dashboard. Determines which locale to be used on render.
 *
 * Keys across all locale files must be in sync
 */

import { Strings as en_ph } from "./en-ph";


let currentLocale = "en-ph";

function getLocaleStrings() {
  const getCurrentLocale = () => {
    currentLocale = (localStorage.getItem('language') || "en") + "-" + (localStorage.getItem('country') || "ph");

    switch (currentLocale) {
      case "en-ph":
        return en_ph;

      default:
        return en_ph;
    }
  };

  return getCurrentLocale();
}

export default getLocaleStrings();
