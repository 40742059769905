const AFFILIATE_NUMBER = 350;

const eventNames = {
  search: 'date_search',
  myPerformanceSearch: 'myperformance_search',
  whosCloseSearch: 'whosclose_search',
  renewalRemindersSearch: 'renewalreminders_search',
  downlineOrderTracking: 'downlineorder_tracking',
  couponRemindersSearch: 'couponreminders_search',
};

const LINK_EVENTS = [eventNames.myPerformanceSearch, eventNames.whosCloseSearch, eventNames.renewalRemindersSearch, eventNames.downlineOrderTracking, eventNames.couponRemindersSearch];

const trackEvent = (eventName, data) => {
  if (typeof window === 'undefined') return;
  if (!window.utag) return;

  const authDetails = getAuthDetails();

  try {
    if (LINK_EVENTS.some(ev => ev === eventName)) {
      return window.utag.link({
        ...window.utag_data,
        ...authDetails,
        ...data,
      });
    }

    return window.utag.view({
      ...window.utag_data,
      ...authDetails,
      ...{
        ...(eventName && { event_name: eventName }),
        ...data,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const trackPage = type => {
  if (!type) return;
  return trackEvent(undefined, {
    ...getPageDetails({ type }),
  });
};

export const trackMyPerfByPeriod = (myPerformance, sortPeriod, pageDetails) => {
  if (!myPerformance || !sortPeriod) return;

  return trackEvent(eventNames.myPerformanceSearch, {
    // award_name: getAwardName(myPerformance?.currentAwardName),
    // award_qualificationPeriod: getAwardQualificationPeriod(myPerformance?.currentAwardQualificationPeriod),
    // leadership_percent: getLeadershipPercent(myPerformance?.currentLeadershipPercent),
    // active_ABOs: getActiveABO(myPerformance?.totalActiveABOs),
    // active_APCs: getActiveAPC(myPerformance?.totalActiveAPCs),
    // sort_Period: sortPeriod,
    ...getPageDetails(pageDetails),
  });
};

export const trackWhosClose = (whosClose, sortPeriod, pageDetails) => {
  if (!whosClose || !sortPeriod) return;

  return trackEvent(eventNames.whosCloseSearch, {

    ...getPageDetails(pageDetails),
  });
}

export const trackRenewalReminders = (renewalReminders, sortPeriod, pageDetails) => {
  if (!renewalReminders || !sortPeriod) return;

  return trackEvent(eventNames.renewalRemindersSearch, {
    // expiredABOSixMonthsGrace_ABO: getABONo(renewalReminders?.expiredABOSixMonthsGrace),
    // expiredAPCOneMonthGrace_ABO: getABONo(renewalReminders?.expiredAPCOneMonthGrace),
    // expiredCurrentMonthNoAutoRenewal_ABO: getABONo(renewalReminders?.expiredCurrentMonthNoAutoRenewal),
    // expiredCurrentMonthWithAutoRenewal: getABONo(renewalReminders?.expiredCurrentMonthWithAutoRenewal),
    // loginAda: getLoginAda(renewalReminders?.loginAda),
    // sort_Period: sortPeriod,
    ...getPageDetails(pageDetails),
  });
}

export const trackDownlineOrderTracking = (downlineOrderTracking, sortPeriod, pageDetails) => {
  if (!downlineOrderTracking || !sortPeriod) return;

  return trackEvent(eventNames.downlineOrderTracking, {

    ...getPageDetails(pageDetails),
  });
}

export const trackCouponReminders = (couponReminders, sortPeriod, pageDetails) => {
  if (!couponReminders || !sortPeriod) return;

  return trackEvent(eventNames.couponRemindersSearch, {
    // couponListABO: getABONo(couponReminders?.couponListABO),
    // couponListAPC: getABONo(couponReminders?.couponListAPC),
    // loginAda: getLoginAda(couponReminders?.loginAda),
    // sort_Period: sortPeriod,
    ...getPageDetails(pageDetails),
  });
}

const getPageDetails = page => ({
  ...(page?.category && { page_category: massageAttrVal(page?.category) }),
  ...(page?.subCategory && {
    page_subCategory: massageAttrVal(page?.subCategory),
  }),
  ...(PAGE_PROPERTIES[page?.type] || {}),
});

const getAuthDetails = () => {
  const loginAda = localStorage.getItem('abo');

  if (!loginAda)
    return {
      visitor_userProfile: 'guest',
    };

  let currentPath = window.location.hostname;
  let currencyCode = '';
  if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
    const country = localStorage.getItem('country') || "ph";
    switch (country) {
      case "ph":
        currencyCode = 'PHP';
        break;
      default:
        currencyCode = 'PHP';
        break;
    }

  } else if (currentPath === 'uat.biz.amway.com.ph' || currentPath === 'biz.amway.com.ph' || currentPath === 'psup.biz.amway.com.ph') {
    currencyCode = 'PHP';

  } 

  return {
    site_country: localStorage.getItem('country'),
    site_language: localStorage.getItem('language'),
    site_currencyCode: currencyCode,
    visitor_imcID: getIMCID(loginAda),
    visitor_partyID: localStorage.getItem('globalPartyID'),
    visitor_pinNumber: localStorage.getItem('pinNumber'),
  };
};

const getIMCID = loginAda => massageAttrVal(AFFILIATE_NUMBER * 100000000000 + Number(loginAda));

// const getAwardName = awardName => massageAttrVal(awardName);
// const getAwardQualificationPeriod = awardQualificationPeriod => massageAttrVal(awardQualificationPeriod);
// const getLeadershipPercent = leadershipPercent => massageAttrVal(leadershipPercent);
// const getActiveABO = activeABO => massageAttrVal(activeABO);
// const getActiveAPC = activeAPC => massageAttrVal(activeAPC);

// const getABONo = reminders => reminders?.map(reminder => reminder?.abo)?.map(massageAttrVal);
// const getLoginAda = loginAda => massageAttrVal(loginAda);

const massageAttrVal = val => String(val != null ? val : 'none');

export const PAGES = {
  hp: 'HP',
  whosClose: 'WHOSCLOSE',
  renewalReminders: 'RENEWALREMINDERS',
  downlineOrderTracking: 'DOWNLINEORDERTRACKING',
  couponReminders: 'COUPONREMINDERS',
};

const PAGE_PROPERTIES = {
  HP: {
    page_category: 'myperformance',
    page_name: 'homepage',
    page_section: 'home',
    page_subCategory: 'myperformance',
  },
  WHOSCLOSE: {
    page_category: 'whosclose',
    page_name: 'whosclose',
    page_section: 'whosclose',
    page_subCategory: 'whosclose',
  },
  RENEWALREMINDERS: {
    page_category: 'renewalreminders',
    page_name: 'renewalreminders',
    page_section: 'renewalreminders',
    page_subCategory: 'renewalreminders',
  },
  DOWNLINEORDERTRACKING: {
    page_category: 'downlineordertracking',
    page_name: 'downlineordertracking',
    page_section: 'downlineordertracking',
    page_subCategory: 'downlineordertracking',
  },
  COUPONREMINDERS: {
    page_category: 'couponreminders',
    page_name: 'couponreminders',
    page_section: 'couponreminders',
    page_subCategory: 'couponreminders',
  },
};
